import React, { useState } from 'react';
import { SiX } from "react-icons/si";
import './contact.scss';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        mail: '',
        phone: '',
        details: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const isValidEmail = (email) => {
        // メールアドレスの正規表現パターン
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        
        if (!formData.name || !formData.mail) {
            alert('名前とメールアドレスは必須です');
            return;
        }

        if (!isValidEmail(formData.mail)) {
            alert('有効なメールアドレスを入力してください');
            return;
        }
        // 送信が完了した後の処理
        // console.log('送信されたデータ:', formData);
        
        // フォ��ムの無効化
        const nameInput = document.getElementById('name');
        const mailInput = document.getElementById('mail');
        const phoneInput = document.getElementById('phone');
        const detailsInput = document.getElementById('details');
        const submitButton = document.getElementById('submit-button');
        
        if (nameInput && mailInput && phoneInput && detailsInput && submitButton) {
            nameInput.disabled = true;
            mailInput.disabled = true;
            phoneInput.disabled = true;
            detailsInput.disabled = true;
            submitButton.disabled = true;
            submitButton.innerText = '送信が完了しました';
            
            // ここでAPIへのリクエストを行うか、他の処理を行う
            fetch('https://05k859d53k.execute-api.ap-northeast-1.amazonaws.com/prod/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                mode: 'cors' // CORSリクエストを許可
            })
            .then(response => response.json())
            .then(data => {
                // console.log('Success:', data);
                // 送信が完了した後の追加の処理をここに記述
            })
            .catch((error) => {
                // console.error('Error:', error);
                // エラーが発生した場合の処理をここに記述
            });
        } else {
            // console.error('要素が見つかりません');
        }
    };

    return (
        <section id='contact' className='section section6'>
            <div className='title contact'>
                <h2>Contact</h2>
            </div>
            <div className='details contact'>
                <form id='form_contact' method='post' action='#' onSubmit={handleSubmit}>
                    <div className='form_row'>
                        <label htmlFor='name'>お名前 / 会社名</label>
                        <input type='text' name='name' id='name' value={formData.name} onChange={handleChange} />
                    </div>
                    <div className='form_row'>
                        <label htmlFor='mail'>メールアドレス</label>
                        <input type='text' name='mail' id='mail' value={formData.mail} onChange={handleChange} />
                    </div>
                    <div className='form_row'>
                        <label htmlFor='phone'>連絡先電話番号</label>
                        <input type='text' name='phone' id='phone' value={formData.phone} onChange={handleChange} />
                    </div>
                    <div className='form_row'>
                        <label htmlFor='details'>お問い合わせ内容</label>
                        <textarea name='details' id='details' rows={5} value={formData.details} onChange={handleChange}></textarea>
                    </div>
                    <div className='form_row'>
                        <button type='submit' id='submit-button'>送信</button>
                    </div>
                </form>
                <div className='list_link'>
                    <p class="list_link_x"><a href='https://x.com/Studio_NoppoSan' target='_blank' rel='noreferrer'><SiX /></a></p>
                    <p class="list_link_cw"><a href='https://crowdworks.jp/public/employees/5884648' target='_blank' rel='noreferrer'>CW</a></p>
                </div>
            </div>
        </section>
    );
}

export default Contact;