import React, { useState } from 'react';
import Header from './components/header/header';
import Main from './components/main/main';
import Footer from './components/footer/footer';
import Nav from './components/nav/nav';
import 'ress';
import './App.scss';

function App() {
  const [activeSection, setActiveSection] = useState('');

  const handleSectionChange = (sectionId) => {
    setActiveSection(sectionId);
  };

  return (
    <>
      <Header />
      <Main onSectionChange={handleSectionChange} />
      <Footer />
      <Nav activeSection={activeSection} />
    </>
  );
}

export default App;