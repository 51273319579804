import React from 'react';
import './profile.scss';

function Profile() {
  return (
    <section id='profile' className='section section2'>
      <div className='title profile'>
        <h2>Profile</h2>
      </div>
      <div className='details profile'>
        <p>
          生まれは石川県金沢市で、現在は千葉県松戸市在住。<br />
          高専で電子情報科を卒業後、専門学校に進学しました。HTML/CSS/デザインは独学で習得し、上京後はデザイン事務所で経験を積みました。その後、某大手通信企業でシステム運用スタッフとしての経験を積んだ後、某大手IT企業へと転職。<br />
          最初はデータセンタのスタッフとしてスタートし、次第にサーバーインフラエンジニアへとキャリアチェンジ。その後、システム開発部門に異動し、DXシステム開発に携わりました。<br />
          上流工程から下流まで一貫した対応を行い、いつの間にかフルスタックエンジニアに成長。<br />
          独立後は某オンラインITスクールでメンターとして活動し、主にフロントエンド開発を指導。企業研修向けにはWindows/Linuxサーバ構築やデータベース構築も担当。上流工程から全てを一貫してワンストップで管理し、開発に携わっています。
        </p>
      </div>
    </section>
  );
}

export default Profile;