import React from 'react';
import './portfolio.scss';

function Portfolio() {
  return (
    <section id='portfolio' className='section section4'>
      <div className='title services'>
        <h2>Portfolio</h2>
      </div>
      <div className='details services'>
        <h3>某大手IT企業</h3>
        <p>
          2005年～2017年 SEとして某大手IT総合企業にて以下の業務に従事<br />
          - オンプレサーバーインフラ構築 / 管理<br />
          - 業務管理Webシステム(DX)構築 / 管理<br />
          - システム要件定義 / 仕様策定<br />
          - マネジメント業務
        </p>
        <h3>独立後</h3>
        <p>
          2018年～現在 独立しフリーランスエンジニアとして活動中<br />
          ・某オンラインITスクールメンター(講師)<br />
          　- 2019年～現在まで、350名の個別指導を担当<br />
          　- フロントエンド / Windowsサーバー / Linuxサーバー / SQL / DataBase / C# / .net / 企業向けセミナー<br />
          ・Webサイト制作<br />
          　- デザイン / LP / コーポレートサイト / jQuery / React<br />
          ・Webアプリケーション開発<br />
          ・バックエンド開発<br />
          　- php (現在は受け付けておりません)<br />
          　- node.js (現在は受け付けておりません)<br />
          ・サーバーインフラ構築<br />
          　- オンプレ / AWS(EC2～サーバーレス)<br />
          ・PC制作 / サーバマシン制作 / 選定<br />
          ・DXコンサルティング<br />
          ・ITコンサルティング / マネジメント<br />
          ・システムコンサルティング
        </p>
      </div>
    </section>
  );
}

export default Portfolio;