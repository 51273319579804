import React from 'react';
import './top.scss';

function Top() {
  return (
    <section id='top' className="section section1">
      <div className='top_image'>
        <img src="/images/logo_square.svg" alt="" />
      </div>
      <div className='catch'>
        <p>Empowering Everyone with the Blessings of IT Expertise.</p>
      </div>
    </section>
  );
}

export default Top;