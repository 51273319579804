import React from 'react';
import './nav.scss';

function Nav({ activeSection }) {
  return (
    <nav id='pagination'>
      <a id='pagination1' href='#top' className={activeSection === 'top' ? 'active' : ''} aria-label='top'></a>
      <a id='pagination2' href='#profile' className={activeSection === 'profile' ? 'active' : ''} aria-label='profile'></a>
      <a id='pagination3' href='#services' className={activeSection === 'services' ? 'active' : ''} aria-label='services'></a>
      <a id='pagination4' href='#portfolio' className={activeSection === 'portfolio' ? 'active' : ''} aria-label='portfolio'></a>
      {/* <a id='pagination5' href='#skills' className={activeSection === 'skills' ? 'active' : ''} aria-label='skills'></a> */}
      <a id='pagination6' href='#contact' className={activeSection === 'contact' ? 'active' : ''} aria-label='contact'></a>
    </nav>
  );
}

export default Nav;