import React from 'react';
import './header.scss';

function Header() {
  return (
    <header>
      <h1 className='logo'>
        <img src="/images/logo_long.svg" alt="Studio NoppoSan" />
      </h1>
      <ul className="top_nav_list">
        {/* <li><a href="#">list1</a></li>
        <li><a href="#">list1</a></li> */}
      </ul>
    </header>
  );
}

export default Header;