import React from 'react';
import Top from './top/top';
import Profile from './profile/profile';
import Services from './services/services';
import Portfolio from './portfolio/portfolio';
import Skills from './skills/skills';
import Contact from './contact/contact';
import './main.scss';

function Main({ onSectionChange }) {
  // Intersection Observerの設定
  const observerCallback = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        onSectionChange(entry.target.id);
      }
    });
  };

  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);

  // セクションの要素を監視
  React.useEffect(() => {
    const sections = document.querySelectorAll('.section');
    sections.forEach(section => observer.observe(section));

    return () => {
      sections.forEach(section => observer.unobserve(section));
    };
  }, []);

  return (
    <main>
      <div className="section"><Top /></div>
      <div className="section"><Profile /></div>
      <div className="section"><Services /></div>
      <div className="section"><Portfolio /></div>
      {/* <div className="section"><Skills /></div> */}
      <div className="section"><Contact /></div>
    </main>
  );
}

export default Main;