import React from 'react';
import './services.scss';

function Services() {
  return (
    <section id='services' className='section section3'>
      <div className='title services'>
        <h2>Services</h2>
      </div>
      <div className='details services'>
        <h3>システム開発</h3>
        <p>
          Webサイト構築からDXシステム開発まで行います。<br />
          要件定義や仕様策定といった上流工程から、実際の開発からリリースまで一貫した業務遂行が可能です。
        </p>
        <h3>コンサルティング業務</h3>
        <p>
          現状のプロセスやシステムの見直し、ニーズに合わせたシステム/ソフトウェア/ハードウェアの選定、プロジェクトのマネジメントまで行います。<br />
          システムやソフトウェアのセキュリティ設計や法令遵守に関するアドバイスと対策の提案も行っております。<br />
          またIT技術全般のトレーニング/教育を提供します。
        </p>
        <h3>制作</h3>
        <p>
          ニーズに合わせたPC/サーバーマシンの選定、制作を行います。
        </p>
      </div>
    </section>
  );
}

export default Services;