import React from 'react';
import './footer.scss';

function Footer() {
  const getCurrentYear = () => new Date().getFullYear();

  return (
    <footer>
      <p>&copy; {getCurrentYear()} Studio NoppoSan.</p>
    </footer>
  );
}

export default Footer;